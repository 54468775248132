@import '../../../theme/styles/mixins.scss';

.container {
  width: 460px;
  margin-left: 24px;
  min-height: 252px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
  cursor: unset !important;

  @include tablet {
    width: 400px;
    min-width: unset;
  }

  &.sticky {
    position: sticky;
    top: 100px;
  }
}

.placeholderWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  svg {
    height: 48px;
    width: 48px;
    fill: var(--colors-surfaces-primary-1);
    margin-bottom: 16px;
  }

  p {
    text-align: center;
    max-width: 220px;
  }
}
