@import '../../../theme/styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
}

.header {
  @include mobile {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: var(--colors-surfaces-primary-0);
    padding-bottom: 12px;
    margin-bottom: 12px !important;
  }
}

.methodSelectionLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  svg {
    width: 20px;
    height: 20px;
    padding: 4px;
    fill: var(--colors-text-body-1);
    background-color: var(--colors-highlights-3);
    border-radius: 50px;
    flex-shrink: 0;
  }
}

.orderMethodSelection {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.orderMethodOptions {
  display: flex;
  gap: 16px;

  button {
    width: 100%;
    height: 66px;
    border-radius: 8px;
    background-color: var(--colors-surfaces-primary-4);

    &:hover {
      background-color: var(--colors-button-secondary-background-hover-1);
    }

    div {
      padding: 0;
    }

    p {
      font-size: 14px;
    }

    svg {
      height: 16px;
      width: 16px;
      fill: var(--colors-text-body-2);
    }
  }
}
