@import '../../theme/styles/mixins.scss';

.wrapper {
  flex-direction: row;
  display: flex;
  padding: 32px;

  @include mobile {
    padding: 16px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  section {
    h3 {
      margin-bottom: 24px;
      margin-top: 32px;
    }
  }
}

.headerSection {
  p {
    color: var(--colors-text-body-2);
  }
  h2 {
    line-height: 35px;
  }
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;

  @include xDesktop {
    grid-template-columns: repeat(3, 1fr);
  }

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mobile {
    grid-template-columns: repeat(1, 1fr);
  }

  &.wl {
    grid-template-columns: repeat(2, 1fr);

    &.drawerOpen {
      grid-template-columns: repeat(1, 1fr);

      @include xxxDesktop {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    @include xDesktop {
      grid-template-columns: repeat(1, 1fr);
    }

    @include xxxDesktop {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.footerSection {
  display: flex;
  margin-top: 32px;
  justify-content: center;

  button {
    min-width: 150px;
    background-color: transparent;
  }
}

.searchInputContainer {
  margin-bottom: 24px;
  width: calc((100% - 20px) / 2);

  input {
    border-radius: 58px;
    height: 46px;
    font-size: 16px;
    border: 1px solid var(--colors-surfaces-primary-1);
  }

  @include xDesktop {
    width: 100%;
  }

  @include xxxDesktop {
    width: calc((100% - 40px) / 3);
  }

  &.drawerOpen {
    width: 100%;
    
    @include xxxDesktop {
      width: calc((100% - 20px) / 2);
    }
  }
}

.rightContainer {
  @include mobile {
    display: none;
  }
}

.leftContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}
