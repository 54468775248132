@import '../../../theme/styles/mixins.scss';

.accordion {
  padding: 8px;
  border-radius: 5px;

  &.expanded,
  &:hover {
    background-color: var(--colors-surfaces-primary-4);
  }

  @include mobile {
    padding: 6px;
  }
}

.caret {
  height: 24px;
  fill: var(--colors-text-body-2);
  margin: 0;
}

.headerContainer {
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
  gap: 16px;
  padding: 0;
}

.header {
  display: inline-flex;
  flex: 1;
  justify-content: space-between;
}

.headerAttendeeTotal {
  text-wrap: nowrap;
}

.headerItemsCount {
  transition: opacity 0.3s ease-out;

  &.expanded {
    visibility: hidden;
    opacity: 0;
  }
}

.headerLeft {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.emptyAttendeeItemList {
  border: none;
  padding: 0 10px 10px;

  img {
    display: none;
  }

  h2 {
    margin-top: 0;
    font-size: 16px;
    color: var(--colors-text-body-0);
  }

  p {
    margin: 12px 0;
    @include mobile {
      margin: 10px 0;
    }
  }
}
