.row {
  flex-direction: row;
  display: flex;
  margin: 4px 0;

  &.spaceBetween {
    justify-content: space-between;
  }

  svg {
    height: 20px;
    width: 20px;
    margin-right: 16px;
  }

  .innerRow {
    flex-direction: row;
    display: flex;

    svg {
      // Fix for icon alignment
      margin-left: -2px;
      margin-right: 15px;
      height: 24px;
      width: 24px;
    }
  }
}