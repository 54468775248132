@import '../../../theme/styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.content {
  @include mobile {
    padding: 16px 16px 32px;
  }
}

.orderReceivedNote {
  display: flex;
  flex-direction: column;
  background-color: var(--colors-surfaces-primary-0);
  height: fit-content;
  border-radius: 12px;

  @include mobile {
    box-shadow: 0px 11px 30px 0px rgba(0, 0, 0, 0.15);
    padding: 16px 16px 32px;
  }
}

.noteHeader {
  display: flex;
  flex-direction: column;
  gap: 32px;

  h3 {
    color: var(--colors-text-body-0);
    font-weight: var(--font-heading-weight);
  }

  h2 {
    color: var(--colors-text-body-4);
    margin-bottom: 20px;
    line-height: normal;
  }

  svg {
    width: 48px;
    height: 48px;
    color: var(--colors-text-body-4);
  }
}

.footer {
  background-color: var(--colors-surfaces-primary-0);

  @include mobile {
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
    padding: 16px 16px 32px;
    position: sticky;
    width: 100%;
    bottom: 0;
    margin-top: 0;
  }
}
