.selectButton {
  width: 100%;
  height: 66px;
  border-radius: 8px;
  background-color: var(--colors-surfaces-primary-4);
  border-style: solid;
  border-width: 2px;
  border-color: var(--colors-surfaces-primary-4);

  &::after {
    border-radius: 8px;
    background-color: var(--colors-surfaces-primary-4);
  }

  &:not(.selected) {
    &:hover::after,
    &:focus::after {
      background-color: var(--colors-button-secondary-background-hover-1);
      border-color: var(--colors-button-secondary-background-hover-1);
    }
  }

  div {
    padding: 0;
  }

  p {
    font-size: 14px;
  }

  svg {
    height: 16px;
    width: 16px;
    fill: var(--colors-text-body-2);
  }

  &.selected,
  &.selected::after {
    background-color: var(--colors-highlights-3) !important;
    border-color: var(--colors-borders-tertiary) !important;
  }
}
