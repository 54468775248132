.caret {
  height: 32px;
  margin: 0 8px;
  fill: var(--colors-text-body-0);
  align-self: center;
}

.headerContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 0;
}

.orderFormatsContainer {
  margin-top: 15px;
}

.orderFormats {
  grid-template-columns: repeat(3, 1fr);
  flex: 1;

  button {
    height: 66px;
    background-color: var(--colors-surfaces-primary-4);

    &:hover {
      background-color: var(--colors-button-secondary-background-hover-1);
    }

    div {
      padding: 0;
    }

    p {
      font-size: 14px;
    }

    svg {
      height: 16px;
      width: 16px;
    }
  }
}

.selectedOrderFormat {
  background-color: var(--colors-highlights-3) !important;
  border: 2px solid var(--colors-borders-tertiary) !important;
}

.emptyOrderFormats {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
}
