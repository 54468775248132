.outletMetaContainer {
  display: flex;
  flex-direction: column;
  padding: 20px 20px;

  h2 {
    font-size: 24px;
    line-height: normal;
    margin-bottom: 8px;
  }
}

.mapContainer {
  height: 160px;
}

.mapWrapper {
  max-width: 100%;

  > div {
    position: unset;
  }
}

.orderFormats {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;

  > div {
    margin-right: 20px;
  }

  :first-child {
    padding-left: 0px;
  }

  :last-child {
    margin-right: 0;
  }
}

.orderFormat {
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    margin-right: 4px;
  }

  svg {
    color: var(--colors-button-text-primary-0);
    width: 18px;
    height: 18px;
  }
}
