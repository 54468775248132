.dateContainer {
  flex: 1;
  background-color: var(--colors-components-calendar-date-background-0);
  border: 2px solid var(--colors-components-calendar-date-background-0);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  height: 32px;

  &:hover:not(.disabled) {
    filter: brightness(0.95);
  }

  span {
    color: var(--colors-components-calendar-date-text-0);
    align-self: center;
    font-weight: var(--font-heading-weight);
  }

  &.outsideOfMonth {
    background-color: transparent;
    border: 2px solid transparent;
    span {
      color: var(--colors-text-body-8);
    }
  }

  &.today {
    background-color: var(--colors-components-calendar-date-background-1);
    border: 2px solid var(--colors-components-calendar-date-text-1);
    span {
      color: var(--colors-components-calendar-date-text-0);
    }
  }

  &.selected {
    background-color: var(--colors-components-calendar-date-background-2);
    border: 2px solid var(--colors-components-calendar-date-background-2);
    span {
      color: var(--colors-text-body-3);
    }
  }

  &.highlighted {
    background-color: var(--colors-highlights-0);
    border: 2px solid var(--colors-highlights-0);
  }
}
