.container {
  flex: 3;
  padding: 16px;
  background-color: var(--colors-surfaces-primary-0);
  border-radius: 12px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
  height: fit-content;

  &.disabled {
    opacity: 0.7;
    pointer-events: none;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  h3 {
    font-weight: var(--font-heading-weight);
  }
}

.selectButton {
  height: 32px;
  padding: 8px 12px;
  background-color: var(--colors-surfaces-primary-4);

  &:hover,
  &:focus {
    background-color: var(--colors-surfaces-primary-2);
  }
}
