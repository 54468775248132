@import '../../theme/styles/mixins.scss';

.legend {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;

  @include smallMobile {
    gap: 10px;
  }
}

.legendItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.marker {
  width: 16px;
  height: 16px;
  border-radius: 4px;

  &.today {
    background-color: var(--colors-components-calendar-date-background-1);
    border: 2px solid var(--colors-components-calendar-date-text-1);
  }

  &.selected {
    background-color: var(--colors-components-calendar-date-background-2);
    border: 2px solid var(--colors-components-calendar-date-background-2);
  }

  &.highlighted {
    background-color: var(--colors-highlights-0);
    border: 2px solid var(--colors-highlights-0);
  }
}
