.container {
  display: flex;
  flex-direction: column;
}

.calendarWeekDays {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  height: 30px;

  span {
    width: 100%;
    text-align: center;
    color: var(--colors-text-body-8);
  }
}

.calendarMonth {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 8px;
}

.calendarWeek {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

@keyframes shimmer {
  100% {
    background-position-x: 0%;
  }
}

.shimmerOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  /** Background shimmer effect */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.7) 30%,
    transparent 50%,
    rgba(255, 255, 255, 0.7) 70%
  );
  background-size: 300%;
  background-position-x: 100%;
  animation: shimmer 1.3s infinite linear;
}
